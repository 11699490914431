import * as React from 'react'
import Loader from '../../misc/Loader'
import { postMessage } from '../../../Api'
import { PostMessageRequest } from '../../interfaces'
import './contact.scss'
export interface ContactObject {
  name: string
  email: string
  company: string
  position: string
  message: string
  forgottenNameEmail: string
  incorrectEmail: string
  noMessage: string
  successSent: string
  failSent: string
  sendButtonText: string
}
export interface ContactProps {
  localeCode: string
  staticTextsContact: ContactObject
}
export interface ContactState {
  name: string
  email: string
  company: string
  position: string
  message: string
  loading?: boolean
  success?: string
  error?: string
}

class ContactPage extends React.Component<ContactProps, ContactState> {
  constructor(props: ContactProps) {
    super(props)
    this.state = {
      name: '',
      email: '',
      company: '',
      position: '',
      message: '',
    }
  }

  componentDidMount() {
    this.setState({ success: '', error: '' })
  }

  verifyemail() {
    const email_reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!email_reg.test(String(this.state.email).toLowerCase())) {
      //console.log("Email is incorrect")
      return false
    } else {
      return true
    }
  }

  async handleSubmit(e: React.FormEvent) {
    e.preventDefault()
    this.setState({ success: '' })
    const { forgottenNameEmail, incorrectEmail, noMessage, successSent, failSent } =
      this.props.staticTextsContact
    const { localeCode } = this.props

    if (this.state.name === '' || this.state.email === '') {
      this.setState({
        error: forgottenNameEmail[localeCode]
          ? forgottenNameEmail[localeCode]
          : forgottenNameEmail['en'],
      })
    } else if (!this.verifyemail()) {
      this.setState({
        error: incorrectEmail[localeCode]
          ? incorrectEmail[localeCode]
          : incorrectEmail['en'],
      })
    } else if (this.state.message === '') {
      this.setState({
        error: noMessage[localeCode] ? noMessage[localeCode] : noMessage['en'],
      })
    } else {
      this.setState({ loading: true, error: '' })

      let body: PostMessageRequest = {
        name: this.state.name,
        email: this.state.email,
        company: this.state.company,
        position: this.state.position,
        message: this.state.message,
      }
      const response = await postMessage(body)

      const _this = this
      setTimeout(function () {
        if (response.statusCode === 200) {
          _this.setState({
            loading: false,
            error: '',
            success: successSent[localeCode]
              ? successSent[localeCode]
              : successSent['en'],
            name: '',
            email: '',
            company: '',
            position: '',
            message: '',
          })
        } else {
          _this.setState({
            loading: false,
            error:
              (failSent[localeCode] ? failSent[localeCode] : failSent['en']) +
              `<a href="mailto: info@niradynamics.se" target="_blank">
                    info@niradynamics.se
                  </a>`,
          })
        }
      }, 1000)
    }
  }

  render() {
    const localeCode = this.props.localeCode
    const { name, email, company, position, message, sendButtonText } =
      this.props.staticTextsContact
    return (
      <div id="contactpage" className="page-content">
        <div className="column column67">
          <form className="form-wrapper" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="form-message ">
              {this.state.error && (
                <span
                  className="error"
                  dangerouslySetInnerHTML={{ __html: this.state.error }}
                />
              )}
              {this.state.success && (
                <span
                  className="success"
                  dangerouslySetInnerHTML={{ __html: this.state.success }}
                />
              )}
            </div>
            <input
              autoFocus
              type="text"
              name="name"
              autoComplete="name"
              placeholder={name[localeCode] ? name[localeCode] : name['en']}
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.currentTarget.value })}
            />
            <input
              type="text"
              name="email"
              autoComplete="email"
              placeholder={email[localeCode] ? email[localeCode] : email['en']}
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.currentTarget.value })}
            />
            <input
              type="text"
              name="company"
              autoComplete="company"
              placeholder={company[localeCode] ? company[localeCode] : company['en']}
              value={this.state.company}
              onChange={(e) => this.setState({ company: e.currentTarget.value })}
            />
            <input
              type="text"
              name="position"
              autoComplete="position"
              placeholder={position[localeCode] ? position[localeCode] : position['en']}
              value={this.state.position}
              onChange={(e) => this.setState({ position: e.currentTarget.value })}
            />
            <textarea
              rows={5}
              id="message"
              name="message"
              placeholder={message[localeCode] ? message[localeCode] : message['en']}
              value={this.state.message}
              onChange={(e) => this.setState({ message: e.currentTarget.value })}
            ></textarea>

            <div className="row">
              {this.state.loading ? (
                <button style={{ minWidth: '17rem' }}>
                  <Loader />
                </button>
              ) : (
                <input
                  style={{ minWidth: '17rem' }}
                  type="submit"
                  className="button"
                  value={
                    sendButtonText[localeCode]
                      ? sendButtonText[localeCode]
                      : sendButtonText['en']
                  }
                />
              )}
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default ContactPage
