import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import BlockContent from '@sanity/block-content-to-react'
import { urlFor } from './../../templates/imgBuilder'
import { serializers } from './../../misc/serializers'
import './slideShowContent.scss'

interface SlideShowContentProps {
  body: any
  icon: any
  image: any
  _key: string
}

const SlideShowContent = (props: SlideShowContentProps) => {
  return (
    <div className="slideShowContentContainer card row">
      <div className="column">
        <AnimatePresence>
          <motion.img
            className="icon"
            alt="slideShowIcon"
            src={urlFor(props.icon).url()}
            initial={{ y: 5 }}
            animate={{ y: 0 }}
            exit={{ y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          />
        </AnimatePresence>
        <motion.div>
          <BlockContent blocks={props.body} serializers={serializers} />
        </motion.div>
      </div>
      <div
        className="column image"
        style={{ backgroundImage: `url(${urlFor(props.image).url()})` }}
      ></div>
    </div>
  )
}

export default SlideShowContent
