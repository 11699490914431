export interface NiraVectorLayer {
  name: string
  id: string
  url: String
}

export const VECTOR_LAYERS: Record<string, NiraVectorLayer> = {
  friction: {
    id: 'Friction',
    name: 'Friction',
    url:
      process.env.GATSBY_API_BASE_URL +
      'tiles/vector/aggregated/maintenance-aggregation/{z}/{x}/{y}',
  },
  roughness: {
    id: 'Roughness',
    name: 'Roughness',
    url:
      process.env.GATSBY_API_BASE_URL +
      'tiles/vector/aggregated/roughness-aggregation/{z}/{x}/{y}',
  },
}

export const VECTOR_MAP_KEY = process.env.GATSBY_API_BASE_URL

export const MAP_TILE_LAYERS: Record<string, any> = {
  v1: {
    name: 'V1',
    key: '2rAAs363nYRRQillIEgK',
    url: 'https://api.maptiler.com/maps/a832e6fe-9fd0-4864-8788-65db8fb08206/{z}/{x}/{y}.png',
  },
  v2: {
    name: 'V2',
    key: 'fMgnS558G8rrOOyv5zUm',
    url: 'https://api.maptiler.com/maps/f2c7456f-8144-4983-8e8c-8413c8797b60/{z}/{x}/{y}.png',
  },
}

export interface InterableObject<T> {
  [index: string]: T
}
