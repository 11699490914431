import React from 'react'
import { graphql } from 'gatsby'
import { getContent } from './pageUtils'
import Layout from './../components/layout/Layout'
import SEO from './../misc/seo'
import Header from './../components/header/Header.tsx'
import Footer from '../components/footer/Footer'

export const query = graphql`
  query ($slug: String) {
    sanityNewPage(slug: { current: { eq: $slug } }) {
      title {
        ...LangLocaleText
      }
      _rawContent
      metaData {
        _key
        _type
        description {
          ...LangLocaleContent
        }
        keywords {
          ...LangLocaleText
        }
      }
      slug {
        current
      }
    }
    sanityLockedPage(slug: { current: { eq: $slug } }) {
      title {
        ...LangLocaleText
      }
      _rawContent
      metaData {
        _key
        _type
        description {
          ...LangLocaleContent
        }
        keywords {
          ...LangLocaleText
        }
      }
      slug {
        current
      }
    }
  }
`

const Page = ({ data, pageContext }) => {
  let page = {}
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key]
      if (element !== null) {
        page = element
      }
    }
  }

  const content = getContent(page._rawContent, pageContext.locale)
  const pageid = page.slug
    ? 'page_' + page.slug.current.replace(/[`~!@#$%^&*()|+\-=?;:'",.<>{}[\]\\/]/gi, '_')
    : ''

  //Sorts out the page title that matches the local code
  let pageTitle
  Object.keys(page.title).forEach((key) => {
    if (key === pageContext.locale) {
      pageTitle = page.title[key]
    }
  })

  return (
    <main id={pageid}>
      <Layout id="layout" localeCode={pageContext.locale}>
        <Header localeCode={pageContext.locale} />
        {page.metaData ? (
          <SEO
            title={pageTitle ? pageTitle : ''}
            description={
              page.metaData.description[pageContext.locale]
                ? page.metaData.description[pageContext.locale]
                : ''
            }
            keywords={
              page.metaData.keywords[pageContext.locale]
                ? page.metaData.keywords[pageContext.locale]
                : ''
            }
          />
        ) : (
          <SEO title={pageTitle ? pageTitle : ''}></SEO>
        )}
        <div className="page-content">{content}</div>
        <Footer localeCode={pageContext.locale} />
      </Layout>
    </main>
  )
}

export default Page
