//Checks if slug.current is '/' then it won't be added to the slug.
export const checkSlug = (menuLink, link, localeCode) => {
  if (menuLink.language === 'en') {
    if (link.path.slug.current === '/') {
      return '/'
    } else {
      return '/' + link.path.slug.current
    }
  } else {
    if (link.path.slug.current === '/') {
      return '/' + localeCode
    } else {
      return '/' + localeCode + '/' + link.path.slug.current
    }
  }
}
