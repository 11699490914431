import React from 'react'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import { urlFor } from './../../templates/imgBuilder'
import { removeP, serializers } from './../../misc/serializers'
import { localeCodeBtnPath } from '../../misc/localeCodePathsButtons'

import './horizontalCardLarge.scss'

export interface HorizontalCardLargeContent {
  title: string
  body: any
  cardImage: any
  buttonText?: string
  path?: string
  localeCode: string
}

const HorizontalCardLarge = (props: HorizontalCardLargeContent) => {
  const ImgSrc = urlFor(props.cardImage).url()
  return (
    <section className="content-wrapper horizontalCardLarge">
      <section className="row">
        <div className="column33">
          <h2> {props.title} </h2>
          <BlockContent
            blocks={props.body}
            serializers={{
              types: { block: removeP.types.block, image: serializers.types.image },
            }}
          />
          {props.path && props.buttonText && (
            <Link
              to={localeCodeBtnPath(props.localeCode, props.path) + '/' + props.path}
              className="button"
            >
              {props.buttonText}
            </Link>
          )}
        </div>
        <div className="column67">
          <img
            src={ImgSrc}
            className={ImgSrc.split('.').pop() === 'png' ? '' : 'shadow rounded'}
          />
        </div>
      </section>
    </section>
  )
}

export default HorizontalCardLarge
