import React from 'react'
import './title.scss'

interface TitleProps {
  _type: string
  title: string
  layout: string
}

const Title = (props: TitleProps) => {
  return (
    <div className="content-wrapper titleComponent" data-type={props._type}>
      <h2 className={'title ' + (props.layout ? props.layout : '')}>{props.title}</h2>
    </div>
  )
}

export default Title
