import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import './iconCard.scss'
import { serializers, removeP } from '../../misc/serializers'
import '../../../ambient.d'

import ImgLiveRoadSurfaceMap1 from './../../img/icon-activity.svg'
import ImgLiveRoadSurfaceMap2 from './../../img/icon-cloud-rain.svg'
import ImgLiveRoadSurfaceMap3 from './../../img/icon-truck.svg'
import ImgLiveRoadSurfaceMap4 from './../../img/icon-thumbs-up.svg'

interface IconCardContent {
  _key: string
  body: any
  icon: string
}

interface IconCardsProps {
  iconCardContent: Array<IconCardContent>
  title: string
  buttonText: string
  body: any
  path: string
}

const MapLink = (data: any) => {
  return (
    <section className="card_without_icon">
      <h2 className="title">{data.title}</h2>
      <summary>
        <BlockContent
          blocks={data.body}
          serializers={{
            types: { block: removeP.types.block, image: serializers.types.image },
          }}
        />
      </summary>
      <p></p>
      <Link to={data.path} className="button">
        {data.buttonText}
      </Link>
    </section>
  )
}

const IconCard = (props: IconCardsProps) => {
  //Gets the right icon for the card
  const getIcon = (icon: string) => {
    switch (icon) {
      case 'truck':
        return ImgLiveRoadSurfaceMap3
      case 'activity':
        return ImgLiveRoadSurfaceMap1
      case 'thumbsUp':
        return ImgLiveRoadSurfaceMap4
      case 'rain':
        return ImgLiveRoadSurfaceMap2
      default:
        return ''
    }
  }
  let updatedListContent = props.iconCardContent.map((card) => {
    //Checks that the icon is not an url or a local path
    if (card.icon.length <= 30 || !card.icon.includes('/')) {
      card.icon = getIcon(card.icon)
    }
    return card
  })

  return (
    <section className="card_with_icon_wrapper">
      <div className="content-wrapper">
        {updatedListContent.map((card, index) => (
          <>
            <section className="card_with_icon" key={card._key} data-index={index}>
              <div className="icon">
                <img src={card.icon} alt="" />
              </div>
              <div>
                <BlockContent
                  blocks={card.body}
                  serializers={{
                    types: { block: removeP.types.block, image: serializers.types.image },
                  }}
                />
              </div>
            </section>

            {/* Places the MapLink between the 3rd and 4th card. */}
            {index === 2 ? <MapLink {...props} /> : null}

            {/* Force new line in the content-wrapper every 2nd card. */}
            {index % 2 !== 0 && <div className="flex-break" />}
          </>
        ))}
      </div>
    </section>
  )
}

export default IconCard
