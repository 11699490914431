import { PostMessageRequest, BaseResponse, ErrorResponse } from './src/interfaces'

export async function postMessage(
  messageBody: PostMessageRequest
): Promise<BaseResponse | ErrorResponse> {
  const BASE_URL = process.env.GATSBY_API_BASE_URL

  const requestHeaders: HeadersInit = new Headers()
  requestHeaders.set('Content-Type', 'application/json')

  try {
    const response: Response = await fetch(BASE_URL + 'rsi-saas/contact-us', {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(messageBody),
    })
    var obj = {
      statusCode: response.status,
      ok: response.ok,
      statusText: response.statusText,
      raw: response,
    }
    return obj
  } catch (error) {
    console.log(error)
    return false
  }
}
