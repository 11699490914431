import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { removeP, serializers } from '../../misc/serializers'

interface SmallCardContent {
  title: string
  body: any
  _key: string
}

interface SmallCardProps {
  smallCardContent: Array<SmallCardContent>
}

const SmallCard = (props: SmallCardProps) => {
  return (
    <div className="content-wrapper">
      {props.smallCardContent.map((card) => (
        <section className="card small" key={card._key}>
          <div className="content">
            <h6>{card.title}</h6>
            <summary>
              <BlockContent
                blocks={card.body}
                serializers={{
                  types: { block: removeP.types.block, image: serializers.types.image },
                }}
              />
            </summary>
          </div>
        </section>
      ))}
    </div>
  )
}

export default SmallCard
