import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { checkSlug } from '../../misc/slugCheck'

// Styles & Graphics
import './footer.scss'
import ImgLogo from './../../img/nira-logo-white.svg'

export interface FooterProps {
  localeCode: string
}

const Footer = (props: FooterProps) => {
  const data = useStaticQuery(graphql`
    {
      allSanityMenuPage {
        nodes {
          _rawContent(resolveReferences: { maxDepth: 10 })
          title
          language
          buttonText
        }
      }
    }
  `)

  const footerLinks = data.allSanityMenuPage.nodes.filter((node) => {
    if (node.language === props.localeCode) {
      return node
    }
  })

  const defaultLinks = data.allSanityMenuPage.nodes.filter((node) => {
    if (node.language === 'en') {
      return node
    }
  })

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  //Checks if their are any footerLinks, if not then default to english menu.
  const defaultOrFooterlinks =
    footerLinks[0]._rawContent.length === 0 ? defaultLinks[0] : footerLinks[0]

  return (
    <footer id="footer">
      <div className="content-wrapper wide">
        <div>
          <h6>
            <OutboundLink
              href="https://www.niradynamics.se"
              rel="noreferrer"
              target="_blank"
            >
              niradynamics.se
            </OutboundLink>
          </h6>
          <nav>
            {defaultOrFooterlinks._rawContent.map((link, index) => {
              return (
                <Link
                  key={index}
                  onClick={scrollTop}
                  to={checkSlug(defaultOrFooterlinks, link, props.localeCode)}
                >
                  {link.title}
                </Link>
              )
            })}
            <OutboundLink onClick={scrollTop} href="/map" rel="noreferrer">
              {footerLinks[0].buttonText}
            </OutboundLink>
          </nav>
        </div>

        <hr />

        <div>
          <Link to="/" className="logo">
            <img src={ImgLogo} alt="" />
          </Link>
          <div>
            <a target="_blank" href="/attributions.txt" style={{ paddingRight: '4rem' }}>
              Attributions
            </a>
            <Link onClick={scrollTop} to="/terms" style={{ paddingRight: '4rem' }}>
              Terms & Conditions
            </Link>
            © {new Date().getFullYear()} NIRA{' '}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
