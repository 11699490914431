import * as React from 'react'
import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'

const { Handle } = Slider

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props
  const date = new Date(value)
  const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  const formattedTime = time.slice(0, -2) + '00'

  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${formattedTime}`}
      visible={dragging}
      placement="top"
      key={index}
      overlayInnerStyle={{
        backgroundColor: '#fff',
        color: '#000',
        padding: '0 20px',
        height: '32px',
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  )
}

interface MapSliderProps {
  currentDate: Date
  onAfterSliderChange: (value: number) => void
}

export default class MapSlider extends React.Component<MapSliderProps> {
  startTime: number
  endTime: number
  defaultTime: number

  constructor(props: MapSliderProps) {
    super(props)

    this.startTime = new Date(this.props.currentDate).setHours(0, 0, 0, 0)
    this.endTime = new Date(this.props.currentDate).setHours(23, 59, 59, 999)
    this.defaultTime = new Date(this.props.currentDate).getTime()
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Slider
          min={this.startTime}
          max={this.endTime}
          defaultValue={this.defaultTime}
          handle={handle}
          trackStyle={{
            backgroundColor: '#414ff6',
            height: 6,
            borderRadius: '6px 6px 6px 0',
          }}
          handleStyle={{
            borderColor: '#fff',
            height: 16,
            width: 16,
            boxShadow: '0 2px 12px 1px rgba(0, 0, 0, 0.4)',
            backgroundColor: '#fff',
          }}
          railStyle={{
            backgroundColor: '#dcddff',
            height: 6,
            borderRadius: '6px 6px 0 0',
          }}
          onAfterChange={(value) => this.props.onAfterSliderChange(value)}
        />
      </>
    )
  }
}
