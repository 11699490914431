import React from 'react'

interface HtmlComponentContent {
  body: any //Plain html code eg. <iframe>...</iframe>
}

//Component that sets the body content, fetched from Sanity, to html element.

const HtmlComponent = (props: HtmlComponentContent) => {
  const htmlElement = props.body

  return (
    <div
      dangerouslySetInnerHTML={{ __html: htmlElement }}
      style={{ width: '100%' }}
    ></div>
  )
}

export default HtmlComponent
