import React from 'react'
import Hero from '../components/hero/Hero'
import PlainText from '../components/plainText/PlainText'
import HorizontalCard from '../components/horizontalCard/HorizontalCard'
import MapComponent from '../components/mapcomponent/MapComponent'
import SmallCard from '../components/smallCard/SmallCard'
import Title from '../components/title/Title'
import VerticalCard from '../components/verticalCard/VerticalCard'
import VerticalCardGrid from '../components/verticalCardGrid/VerticalCardGrid'
import HorizontalCardWithBackground from '../components/horizontalCardWithBackground/HorizontalCardWithBackground'
import IconCard from '../components/iconCard/IconCard'
import HorizontalCardLarge from '../components/horizontalCardLarge/HorizontalCardLarge'
import CustomerInsight from '../components/customerInsight/CustomerInsight'
import SlideShow from '../components/slideShow/SlideShow'
import HtmlComponent from '../components/htmlComponent/HtmlComponent'

export function getContent(_rawContent, localeCode) {
  if (!_rawContent) {
    return <></>
  }

  //Translates a whole array recursivly
  const localizeProps = (c) => {
    let localizedProps = {}
    //Loop over and read every property in props
    Object.keys(c).forEach((key) => {
      if (
        (c[key] && typeof c[key] === 'object' && c[key].en) ||
        c[key].de ||
        c[key].jp ||
        c[key].cn
      ) {
        //if property is a translation object select choosen language
        if (c[key][localeCode]) {
          localizedProps[key] = c[key][localeCode]
        } else {
          localizedProps[key] = c[key].en
        }
      } else if (Array.isArray(c[key])) {
        //if property is an array => localize properties of its children
        localizedProps[key] = c[key].map((arrayElement) => localizeProps(arrayElement))
      } else {
        //copy rest
        localizedProps[key] = c[key]
      }
    })
    return localizedProps
  }

  const content = _rawContent.map((c, i) => {
    let comp = null
    c = localizeProps(c)
    c.localeCode = localeCode
    switch (c._type) {
      case 'hero':
        comp = <Hero key={c._key} {...c} />
        break
      case 'title':
        comp = <Title key={c._key} {...c} />
        break
      case 'plainText':
        comp = <PlainText key={c._key} {...c} />
        break
      case 'smallCard':
        comp = <SmallCard key={c._key} {...c} />
        break
      case 'horizontalCard':
        comp = <HorizontalCard key={c._key} {...c} />
        break
      case 'verticalCard':
        comp = <VerticalCard key={c._key} {...c} />
        break
      case 'verticalCardGrid':
        comp = <VerticalCardGrid key={c._key} {...c} />
        break
      case 'horizontalCardWithBackground':
        comp = <HorizontalCardWithBackground key={c._key} {...c} />
        break
      case 'iconCard':
        comp = <IconCard key={c._key} {...c} />
        break
      case 'horizontalCardLarge':
        comp = <HorizontalCardLarge key={c._key} {...c} />
        break
      case 'customerInsight':
        comp = <CustomerInsight key={c._key} {...c} />
        break
      case 'slideShow':
        comp = <SlideShow key={c._key} {...c} />
        break
      case 'map':
        comp = <MapComponent key={c._key} {...c} />
        break
      case 'htmlComponent':
        comp = <HtmlComponent key={c._key} {...c} />
        break

      default:
        comp = (
          <>
            {process.env.NODE_ENV === 'development' ? (
              <div
                className="content-wrapper"
                style={{ backgroundColor: '#ffeeee' }}
                key={c._key}
              >
                <span style={{ padding: '2rem', fontStyle: 'italic', color: 'red' }}>
                  Missing component for "<b>{c._type}</b>".
                </span>
              </div>
            ) : null}
          </>
        )
    }
    return comp
  })

  return content
}
