import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { urlFor } from './../../templates/imgBuilder'
import './verticalCard.scss'
import { removeP, serializers } from '../../misc/serializers'

export interface VerticalCardContent {
  title: string
  cardNumber: string
  body: any
  cardImage: any
}

const VerticalCard = (props: VerticalCardContent) => {
  return (
    <section className="card">
      <div
        className="image"
        style={{ backgroundImage: `url(${urlFor(props.cardImage).url()})` }}
      />
      <div className="content">
        <h5>
          <div className="count">{props.cardNumber}</div> <div>{props.title}</div>
        </h5>
        <summary>
          <BlockContent
            blocks={props.body}
            serializers={{
              types: { block: removeP.types.block, image: serializers.types.image },
            }}
          />
        </summary>
      </div>
    </section>
  )
}

export default VerticalCard
