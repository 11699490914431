import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { urlFor } from './../../templates/imgBuilder'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { removeP, serializers } from '../../misc/serializers'
import './horizontalCardWithBackground.scss'

export interface HorizontalCardWithBackgroundContent {
  title: string
  cardNumber: string
  body: any
  cardImage: any
  buttonText: string
  buttonUrl: string
}

const HorizontalCardWithBackground = (props: HorizontalCardWithBackgroundContent) => {
  return (
    <section className="content-wrapper background-plate">
      <section className="card">
        <h2> {props.title} </h2>
        <div className="row m-row-reverce">
          <div className="column">
            <summary>
              <BlockContent
                blocks={props.body}
                serializers={{
                  types: { block: removeP.types.block, image: serializers.types.image },
                }}
              />
            </summary>
            {props.buttonText && props.buttonUrl && (
              <>
                <br />
                <OutboundLink
                  href={props.buttonUrl}
                  target="_blank"
                  className="button"
                  rel="noreferrer"
                >
                  {props.buttonText}
                </OutboundLink>
              </>
            )}
          </div>
          <div
            className="column image rounded"
            style={{ backgroundImage: `url(${urlFor(props.cardImage).url()})` }}
          />
        </div>
      </section>
    </section>
  )
}

export default HorizontalCardWithBackground
