import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { ButtonWithProgress } from './ButtonWithProgress'
import SlideShowContent from './SlideShowContent'
import './slideShow.scss'

interface SlideShowProps {
  slideShowContent: Array<SlideShowContent>
  _key: string
}

interface SlideShowContent {
  title: string
  body: any
  icon: any
  image: any
  _key: string
}

const INTERVAL = 10000

interface SlideShowProps {}

const SlideShow = (props: SlideShowProps): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(0)
  var timer = useRef(undefined)

  useEffect(() => {
    timer.current = slideShowLoop()
    return () => {
      clearTimeout(timer.current)
    }
  })

  const slideShowLoop = () => {
    return setTimeout(() => {
      setCurrentIndex((index): number => {
        if (index >= props.slideShowContent.length - 1) {
          return 0
        }
        return index + 1
      })
      timer.current = slideShowLoop()
    }, INTERVAL)
  }

  const buttonClicked = (index: number) => {
    clearTimeout(timer.current)
    setCurrentIndex(index)
    timer.current = slideShowLoop()
  }

  const buttons = props.slideShowContent.map((data, i) => {
    return (
      <ButtonWithProgress
        index={i}
        onClick={buttonClicked}
        {...data}
        active={i === currentIndex}
        key={'pb' + data._key}
      />
    )
  })

  const currentSlideData = props.slideShowContent[currentIndex]

  return (
    <section className="content-wrapper slideShowComponent" key={'ss' + props._key}>
      <div className="slideShowContainer">
        <div className="buttonContainer">{buttons}</div>
        <div className="currentSlideContainer">
          <AnimatePresence>
            <motion.div
              className="animationContainer"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0.0 }}
              transition={{ duration: 0.8 }}
            >
              <SlideShowContent {...currentSlideData} />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </section>
  )
}

export default SlideShow
