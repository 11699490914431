import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from './../../misc/serializers'

interface PlainTextContent {
  body: any
}

const PlainTextCard = (props: PlainTextContent) => {
  return (
    <div className="content-wrapper plainTextComponent">
      <section className="card">
        <div className="content">
          <BlockContent blocks={props.body} serializers={serializers} />
        </div>
      </section>
    </div>
  )
}

export default PlainTextCard
