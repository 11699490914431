import React from 'react'
import VerticalCard, { VerticalCardContent } from '../verticalCard/VerticalCard'

interface VerticalCardGridProps {
  verticalCardContent: Array<VerticalCardContent>
}

const VerticalCardGrid = (props: VerticalCardGridProps) => {
  return (
    <div className="content-wrapper verticalComponent">
      {props.verticalCardContent.map((cardProps, index) => {
        return <VerticalCard {...cardProps} key={index} />
      })}
    </div>
  )
}

export default VerticalCardGrid
