import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { urlFor } from './../../templates/imgBuilder'
import './customerInsight.scss'
import { serializers } from './../../misc/serializers'

interface CustomerInsightContent {
  title: string
  subtitle: string
  body: any
  cardImage: any
  _key: string
}

interface CustomerInsightProps {
  customerInsightContent: Array<CustomerInsightContent>
}

const CustomerInsight = (props: CustomerInsightProps) => {
  return (
    <div className="content-wrapper customerInsight">
      {props.customerInsightContent.map((card) => (
        <section className="card vertical" key={card._key}>
          <div className="content">
            <div>
              <img
                style={{ width: 'unset', paddingBottom: '2rem' }}
                src={urlFor(card.cardImage).url()}
                alt=""
              />
            </div>
            <h6> {card.title} </h6>
            <div className="subtitle">{card.subtitle}</div>

            <summary>
              <BlockContent blocks={card.body} serializers={serializers} />
            </summary>
          </div>
        </section>
      ))}
    </div>
  )
}

export default CustomerInsight
