import React from 'react'
import './buttonWithProgress.scss'

interface ButtonWithProgressProps {
  title: string
  active: boolean
  index: number
  _key: string
  onClick: (i) => void
}

export const ButtonWithProgress = (props: ButtonWithProgressProps): JSX.Element => {
  const isActive = props.active ? ' active' : ''
  return (
    <div className="bwpContainer" onClick={() => props.onClick(props.index)}>
      <div className="progressBar">
        <div className={'progressBarFill' + isActive} />
      </div>
      <div className={'buttonTitleText' + isActive}>{props.title}</div>
    </div>
  )
}
