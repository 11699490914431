import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { Link } from 'gatsby'
import { urlFor } from './../../templates/imgBuilder'
import { removeP, serializers } from './../../misc/serializers'
import { localeCodeBtnPath } from '../../misc/localeCodePathsButtons'
import './horizontalCard.scss'

interface HorizontalCardContent {
  title: string
  body: any
  cardImage: any
  path: string
  buttonText: string
}

interface HorizontalCardProps {
  horizontalCardContent: Array<HorizontalCardContent>
  localeCode: string
}

const HorizontalCard = (props: HorizontalCardProps) => {
  return (
    <div className="content-wrapper horizonalComponent">
      {props.horizontalCardContent.map((card, index) => (
        <section className="card" key={index}>
          <div
            className="image"
            style={{ backgroundImage: `url(${urlFor(card.cardImage).url()})` }}
          ></div>
          <div className="content">
            <h3> {card.title} </h3>
            <summary>
              <BlockContent
                blocks={card.body}
                serializers={{
                  types: { block: removeP.types.block, image: serializers.types.image },
                }}
              />
            </summary>
            {card.path && card.buttonText && (
              <Link
                to={localeCodeBtnPath(props.localeCode, card.path) + '/' + card.path}
                className="button"
              >
                {card.buttonText}
              </Link>
            )}
          </div>
        </section>
      ))}
    </div>
  )
}

export default HorizontalCard
