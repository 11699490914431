import * as React from 'react'
import loadable from '@loadable/component'
import Map from 'ol/Map'
import View from 'ol/View'
import { defaults } from 'ol/interaction'
const VectorTileRenderType = loadable(() => import('ol/layer/VectorTileRenderType'))
import { FeatureLike } from 'ol/Feature'
import TileLayer from 'ol/layer/Tile'
import VectorTileLayer from 'ol/layer/VectorTile'
import VectorTileSource from 'ol/source/VectorTile'
import { Attribution, defaults as defaultControls } from 'ol/control'
import MVT from 'ol/format/MVT'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import './mapcomponent.scss'
import MapSlider from '../mapSlider/MapSlider'

import {
  MAP_TILE_LAYERS,
  NiraVectorLayer,
  VECTOR_LAYERS,
  VECTOR_MAP_KEY,
} from './MapConstants'

import chroma from 'chroma-js'

const BASE_VECTOR_COLOR = '#aabbbb'

interface MapComponentProps {
  featureClicked: (feature: FeatureLike) => void
  currentVectorLayerId: string
  vectorLayers: Record<string, NiraVectorLayer>
  colorScale: chroma.Scale<chroma.Color>
}

export default class MapComponent extends React.Component<MapComponentProps> {
  map: Map
  ref: any
  selected: any
  vectorTileLayer: VectorTileLayer | undefined
  selectedFeatures: any
  selectionLayer: VectorTileLayer | undefined
  colorScale: any
  currentDate: Date
  source: VectorTileSource | undefined

  constructor(props: MapComponentProps) {
    super(props)
    this.ref = React.createRef()
    this.selected = null
    this.selectedFeatures = {}
    this.colorScale = chroma
      .scale(['red', 'orange', 'yellow', 'green'])
      .domain([0.35, 0.4, 0.5, 0.7])
    this.currentDate = new Date(2021, 2, 14, 15)

    this.onAfterSliderChange = this.onAfterSliderChange.bind(this)
    const localISOTime = this.currentDate.toISOString().slice(0, -10) + '00:00'
    this.source = new VectorTileSource({
      format: new MVT(),
      maxZoom: 18,
      url:
        VECTOR_LAYERS.friction.url +
        '?access_token=' +
        VECTOR_MAP_KEY +
        '&time=' +
        localISOTime,
    })
  }

  shouldComponentUpdate(props: MapComponentProps) {
    return false
  }

  async componentDidMount() {
    const zoom = 12
    let center = [18.051398, 59.327977]
    const proj = await import('ol/proj')
    let centerMerc = proj.fromLonLat(center)
    var attribution = new Attribution({
      collapsible: false,
      collapsed: true,
    })
    let options = {
      view: new View({ zoom: zoom, center: centerMerc }),
      layers: [],
      overlays: [],
      renderer: 'webgl',
      interactions: defaults({ mouseWheelZoom: false, onFocusOnly: true }),
      controls: defaultControls({
        attribution: true,
        zoom: false,
        rotate: false,
      }).extend([attribution]),
    }

    this.map = new Map(options)
    this.map.setTarget(this.ref.current)
    await this.addTileLayer()
    this.addVectorTileLayer(VECTOR_LAYERS.friction)
  }

  removeVectorTileLayer(layer?: VectorTileLayer) {
    if (!layer) {
      return
    }
    this.map.removeLayer(layer)
  }

  addVectorTileLayer(vectorLayer: NiraVectorLayer) {
    this.vectorTileLayer = new VectorTileLayer({
      source: this.source,
      renderMode: VectorTileRenderType.VECTOR,
      style: (feature, resolution) => {
        let color = BASE_VECTOR_COLOR

        if (feature.getProperties()['Friction-Average']) {
          color = this.colorScale(
            Number(feature.getProperties()['Friction-Average'])
          ).hex()
        }
        const style = new Style({
          stroke: new Stroke({
            color: color,
            width: 3,
          }),
        })

        return style
      },
    })
    this.map.addLayer(this.vectorTileLayer)
  }

  onAfterSliderChange(value: number) {
    const tzoffset = new Date().getTimezoneOffset() * 60000 //offset in milliseconds
    const localISOTime = new Date(value - tzoffset).toISOString().slice(0, -10) + '00:00'

    const url =
      VECTOR_LAYERS.friction.url +
      '?access_token=' +
      VECTOR_MAP_KEY +
      '&time=' +
      localISOTime

    this.source.setUrl(url)
    this.source.refresh()
  }

  getPrettyDate(date: Date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return date.toLocaleDateString(undefined, options)
  }

  async addTileLayer() {
    const source = await import('ol/source')
    const niraSource = new source.XYZ({
      url: MAP_TILE_LAYERS.v2.url + '?key=' + MAP_TILE_LAYERS.v2.key,
      attributions: '<a href="https://maptiler.com/copyright">© MapTiler</a>',
    })

    const zIndex = 0

    let tileLayer = new TileLayer({
      source: niraSource,
      zIndex,
    })

    this.map.addLayer(tileLayer)
    tileLayer.setZIndex(zIndex)
  }

  render() {
    return (
      <div className="content-wrapper map-component">
        <div ref={this.ref} className="mapComponentRoot" id="map" />
        <div className="slider-wrapper">
          <MapSlider
            currentDate={this.currentDate}
            onAfterSliderChange={this.onAfterSliderChange}
          />
          <div className="slider-label-wrapper">
            <p>{this.getPrettyDate(this.currentDate)}</p>
          </div>
        </div>
      </div>
    )
  }
}
