import * as React from 'react'
import './loader.scss'

export interface LoaderProps {}

export interface LoaderState {}

class Loader extends React.Component<LoaderProps, LoaderState> {
  constructor(props: LoaderProps) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    // Divs are used for animation
    return (
      <div className="loader">
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }
}

export default Loader
