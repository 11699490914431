// Adds the chosen language to paths or links, linked with buttons, when localeCode is not english and navigation is not to "map".

export const localeCodeBtnPath = (localeCode, path) => {
  return addLocaleCodeToPath(localeCode, path)
}

//For when the hero-component has links instead of buttons, such as on RSA and RSC.
export const localeCodeLinks = (localeCode, link) => {
  return addLocaleCodeToPath(localeCode, link)
}

const addLocaleCodeToPath = (localeCode, navigation) => {
  if (localeCode !== 'en' && navigation !== 'map' && navigation !== '/map') {
    return '/' + localeCode
  } else {
    return ''
  }
}
