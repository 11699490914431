import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import './hero.scss'
import BlockContent from '@sanity/block-content-to-react'
import { graphql, useStaticQuery } from 'gatsby'
import { urlFor } from './../../templates/imgBuilder'
import { serializers } from './../../misc/serializers'
import ContactPage from '../contact/Contact'
import { localeCodeBtnPath, localeCodeLinks } from '../../misc/localeCodePathsButtons'

interface LinksContent {
  linkText: string
  path: string
}
export interface HeroProps {
  title: string
  buttonText?: string
  path?: string
  heroImg?: any
  newWindow: boolean
  body: any
  _type: string
  heroType: string
  linksContent: Array<LinksContent>
  localeCode: string
}

const Hero = (props: HeroProps) => {
  const [links, setLinks] = useState([])
  const data = useStaticQuery(graphql`
    {
      sanityStaticTextContact {
        ...ContactFragment
      }
    }
  `)

  useEffect(() => {
    getLinks()
  }, [])

  //Checks is there are links and sets them
  const getLinks = () => {
    if (props.linksContent) {
      setLinks(props.linksContent)
    }
  }

  const openNewWindow = (e: React.MouseEvent) => {
    e.preventDefault()
    window.open('/' + props.path)
  }

  const heroImgSrc = urlFor(props.heroImg).url()

  return (
    <section className="top-wrapper">
      <section className="content-wrapper">
        {props.heroType === 'contact' ? (
          <div className="row">
            <div className="column column33">
              <h2 className="title">{props.title}</h2>
              <summary>
                <BlockContent blocks={props.body} serializers={serializers} />
              </summary>
            </div>
            <ContactPage
              localeCode={props.localeCode}
              staticTextsContact={data.sanityStaticTextContact}
            />
          </div>
        ) : (
          // heroType 'default' or 'scenario'
          <div className="row">
            <div className="column">
              <h2 className={'title' + (!heroImgSrc ? ' wide' : '')}>{props.title}</h2>
              <summary>
                <BlockContent blocks={props.body} serializers={serializers} />
              </summary>
              {props.buttonText && (
                <Link
                  to={localeCodeBtnPath(props.localeCode, props.path) + '/' + props.path}
                  className="button white"
                  onClick={(e) => {
                    if (props.newWindow) {
                      openNewWindow(e)
                    }
                  }}
                >
                  {props.buttonText}
                </Link>
              )}
              {links.map((link, index) => (
                <Link
                  to={localeCodeLinks(props.localeCode, link.path) + '/' + link.path}
                  activeClassName="active"
                  partiallyActive={true}
                  className="subpage_navigation"
                  key={index}
                >
                  {link.linkText}
                </Link>
              ))}
            </div>
            {/* <div className="column top_graphics">{heroImgSrc ? <img src={heroImgSrc} className={heroImgSrc.split('.').pop()} /> : <></>}</div> */}

            {heroImgSrc && (
              <div className="column top_graphics">
                <img src={heroImgSrc} className={heroImgSrc.split('.').pop()} />
              </div>
            )}
          </div>
        )}
      </section>
    </section>
  )
}

export default Hero
