import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { checkSlug } from '../../misc/slugCheck'

import './header.scss'
import ImgLogo from './../../img/nira-logo-white.svg'
import ImgHamburger from './../../img/icon-menu-hamburger.svg'
import ImgClose from './../../img/icon-menu-close.svg'

interface Slug {
  current: string
}
export interface NewPage {
  title: string
  slug: Slug
}
export interface HeaderProps {
  localeCode: string
}

const Header = (props: HeaderProps) => {
  const data = useStaticQuery(graphql`
    {
      allSanityMenuPage {
        nodes {
          _rawContent(resolveReferences: { maxDepth: 10 })
          title
          language
          buttonText
          buttonTextMobile
        }
      }
    }
  `)

  const menuLinks = data.allSanityMenuPage.nodes.filter((node) => {
    if (node.language === props.localeCode) {
      return node
    }
  })
  const defaultLinks = data.allSanityMenuPage.nodes.filter((node) => {
    if (node.language === 'en') {
      return node
    }
  })
  const scrollTop = () => {
    closeMenu()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const closeMenu = () => {
    var body = document.body
    body.classList.remove('menu_open')
  }
  const openMenu = () => {
    var body = document.body
    body.classList.add('menu_open')
  }
  const openLanguageMenu = () => {
    var element = document.getElementById('language_wrapper')
    element.className = 'open'
  }
  const closeLanguageMenu = () => {
    var element = document.getElementById('language_wrapper')
    element.className = ''
  }
  const localeCodeForLogo = () => {
    if (props.localeCode !== 'en') {
      return props.localeCode
    } else {
      return ''
    }
  }
  //Checks if their are any menuLinks, if not then default to english menu.
  const defaultOrMenulinks =
    menuLinks[0]._rawContent.length === 0 ? defaultLinks[0] : menuLinks[0]

  return (
    <header id="header">
      <nav>
        <Link to={'/' + localeCodeForLogo()} className="logo">
          <img src={ImgLogo} alt="" />
        </Link>
        <span></span>
        <Link
          onClick={scrollTop}
          to={process.env.GATSBY_MAP_URL}
          className="button inverted mobile_button"
        >
          {defaultOrMenulinks.buttonTextMobile}
        </Link>
        <div className="links">
          {defaultOrMenulinks._rawContent.map((link, index) => (
            <Link
              key={index}
              onClick={scrollTop}
              to={checkSlug(defaultOrMenulinks, link, props.localeCode)}
              activeClassName="active"
            >
              {link.title}
            </Link>
          ))}
          <OutboundLink
            onClick={scrollTop}
            href={process.env.GATSBY_MAP_URL}
            className="button inverted map"
            rel="noreferrer"
          >
            {defaultOrMenulinks.buttonText}
          </OutboundLink>
          <div className="language_select">
            <div
              id="language_wrapper"
              className=""
              onMouseLeave={closeLanguageMenu}
              onClick={closeLanguageMenu}
            >
              <div className="language_list">
                <Link className="flag cn" to="/cn">
                  {' '}
                  中国人{' '}
                </Link>
                <Link className="flag en" to="/">
                  {' '}
                  English{' '}
                </Link>
                <Link className="flag de" to="/de">
                  {' '}
                  Deutsche{' '}
                </Link>
                <Link className="flag jp" to="/jp">
                  {' '}
                  日本語{' '}
                </Link>
              </div>
            </div>
            <div
              className={'selected_language flag ' + props.localeCode}
              onClick={openLanguageMenu}
            />
          </div>
        </div>
        <div className="mobile clickable">
          <img className="open_button" onClick={openMenu} src={ImgHamburger} alt="" />
          <img className="close_button" onClick={closeMenu} src={ImgClose} alt="" />
        </div>
      </nav>
    </header>
  )
}
export default Header
